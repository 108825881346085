import React from "react";
import "./style.css";
import rating from "../../assets/images/rating.jpg";

function Location() {
  return (
    <div
      id="location"
      className="location_container"
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      // className="vc_row wpb_row vc_row-fluid"
      style={
        {
          // textAlign: "center",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // position: "relative",
          // // left: "-161px",
          // boxSizing: "border-box",
          // width: "1492px",
          // paddingLeft: "161px",
          // paddingRight: "161px",
        }
      }
    >
      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="vc_empty_space" style={{ height: "60px" }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div
              id="ultimate-heading-487965002eec3995d"
              className="uvc-heading ult-adjust-bottom-margin ultimate-heading-487965002eec3995d uvc-6471"
              data-hspacer="line_only"
              data-halign="center"
              style={{ textAlign: "center" }}
            >
              <div
                className="uvc-sub-heading ult-responsive"
                data-ultimate-target=".uvc-heading.ultimate-heading-487965002eec3995d .uvc-sub-heading"
                data-responsive-json-new='{"font-size":"desktop:17px;","line-height":"desktop:20px;"}'
                style={{
                  fontFamily: '"Cinzel"',
                  fontWeight: 700,
                  color: "#ffffff",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                <p />{" "}
                <p style={{ marginBottom: "-15px", textAlign: "center" }}>
                  <strong style={{ fontSize: "35px", color: "#374f5a" }}>
                    FUTURISTIC LOCALE : AHMEDABAD, GUJRAT
                  </strong>
                </p>{" "}
                <p />
              </div>
              <div
                className="uvc-heading-spacer line_only"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "2px",
                }}
              >
                <span
                  className="uvc-headings-line"
                  style={{
                    display: "flex",
                    borderStyle: "solid",
                    borderBottomWidth: "2px",
                    borderColor: "rgb(55, 79, 90)",
                    width: "200px",
                    margin: "0px auto",
                  }}
                />
              </div>
            </div>
            <div
              id="ultimate-heading-291065002eec399a5"
              className="uvc-heading ult-adjust-bottom-margin ultimate-heading-291065002eec399a5 uvc-5454"
              data-hspacer="line_only"
              data-halign="left"
              style={{ textAlign: "left" }}
            >
              <div
                className="uvc-heading-spacer line_only"
                style={{
                  marginTop: "-10px",
                  marginBottom: "10px",
                  height: "2px",
                }}
              >
                <span
                  className="uvc-headings-line"
                  style={{
                    borderStyle: "solid",
                    borderBottomWidth: "2px",
                    borderColor: "rgb(255, 255, 255)",
                    width: "80px",
                    float: "left",
                  }}
                />
              </div>
              {/* <div
                className="uvc-sub-heading ult-responsive"
                data-ultimate-target=".uvc-heading.ultimate-heading-291065002eec399a5 .uvc-sub-heading"
                data-responsive-json-new='{"font-size":"desktop:17px;","line-height":"desktop:24px;"}'
                style={{
                  fontFamily: '"Lora"',
                  fontStyle: "italic",
                  fontWeight: "normal",
                  color: "#000000",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                <p />{" "}
                <p
                  style={{
                    marginRight: "42px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  Tracing the Journey and Evolution of Two Cities – Ahmedabad
                  and North View Symphony – How They Complement Each Other in
                  Every Aspect
                  <br /> – Culture, Colour, Education, Care, Facilities, Growth
                  and Development.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="inner_content">
        <div
          className="map-container"
          // className="col-md-6 mb-sm-30 map-contact"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: 0,
          }}
        >
          <iframe
            title="ahmedabad"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.559998023482!2d72.46306277485144!3d23.039922415681385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9be997d38903%3A0x2ec1338c9eb97e65!2sNORTH%20VIEW%20SYMPHONY!5e0!3m2!1sen!2sin!4v1694774744843!5m2!1sen!2sin"
            // width="600"
            height="300"
            style={
              {
                // border: "1px solid #374f5a",
                // width: "600px",
              }
            }
            className="map-layout"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div
          className="uvc-heading ult-adjust-bottom-margin ultimate-heading-303364ccd8c1f3118 uvc-6689"
          style={{ textAlign: "center" }}
        >
          <div
            className="uvc-sub-heading ult-responsive"
            data-responsive-json-new='{"font-size":"desktop:18px;","line-height":"desktop:33px;"}'
            style={{
              fontFamily: "Lora",
              fontStyle: "italic",
              fontWeight: "normal",
              color: "#000000",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <p
              style={{
                marginTop: 20,
                fontSize: 26,
                fontWeight: "bold",
                color: "#374f5a",
                textTransform: "uppercase",
                textDecoration: "underline",
              }}
            >
              Proximeties
            </p>
            <ol className="proximeties">
              <li>Kalupur Railway Station : 18km</li>
              <li>Airport : 22km</li>
              <li>SP Ringroad : 2km</li>
              <li>D.P.S School : 1km</li>
              <li>Krishna Shalby Hospital : 4km</li>
            </ol>
            <p></p>
          </div>
        </div>
      </div>
      <div className="image-container">
        <img className="image-size" alt="null" src={rating} />
      </div>
      {/* <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="vc_empty_space" style={{ height: "30px" }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="ult-just-icon-wrapper">
              <div className="align-icon" style={{ textAlign: "center" }}>
                {" "}
                <div
                  className="aio-icon-img"
                  style={{ fontSize: "1500px", display: "inline-block" }}
                >
                  {" "}
                  <img className="img-icon" alt="null" src={rating} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Location;
