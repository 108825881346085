import React from "react";
import "./style.css";
import amenities from "../../assets/images/Amenities.jpeg";
import SectionAccordion from "../SectionAccordion";
import SectionAccordion2 from "../SectionAccordion2";

function Amenities() {
  return (
    <div
      id="amenities"
      className="amenities_container"
      // className="vc_row wpb_row vc_row-fluid"
      style={
        {
          // display: "flex",
          // position: "relative",
          // // left: "-161px",
          // boxSizing: "border-box",
          // width: "1492px",
          // paddingLeft: "161px",
          // paddingRight: "161px",
          // flexDirection: "column",
        }
      }
    >
      <div className="text-container">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="vc_empty_space" style={{ height: "30px" }}></div>
              <div
                id="ultimate-heading-945265002eec2d1f0"
                className="uvc-heading ult-adjust-bottom-margin ultimate-heading-945265002eec2d1f0 uvc-8561"
                data-hspacer="no_spacer"
                data-halign="center"
                style={{ textAlign: "center" }}
              >
                <div
                  className="uvc-heading-spacer no_spacer"
                  style={{ top: "" }}
                ></div>
                <div
                  className="uvc-sub-heading ult-responsive"
                  data-ultimate-target=".uvc-heading.ultimate-heading-945265002eec2d1f0 .uvc-sub-heading"
                  data-responsive-json-new='{"font-size":"desktop:23px;","line-height":"desktop:30px;"}'
                  style={{
                    fontFamily: "Cinzel",
                    fontStyle: "italic",
                    fontWeight: "normal",
                    color: "#000000",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <p></p>
                  <p style={{ textAlign: "center", fontSize: "18px" }}>
                    Limitless Living at
                    <br />
                    <strong style={{ fontSize: "40px", color: "#374f5a" }}>
                      North View Symphony&nbsp;
                    </strong>
                  </p>
                </div>
              </div>
              <div
                id="ultimate-heading-885565002eec2d248"
                // className="uvc-heading ult-adjust-bottom-margin ultimate-heading-885565002eec2d248 uvc-7019"
                style={{ textAlign: "center" }}
              >
                <div
                  className="uvc-heading-spacer line_only"
                  style={{
                    marginTop: "-10px",
                    marginBottom: "-10px",
                    height: "2px",
                  }}
                >
                  <span
                    className="uvc-headings-line"
                    style={{
                      display: "flex",
                      borderStyle: "solid",
                      borderBottomWidth: "1px",
                      borderColor: "rgb(55, 79, 90)",
                      width: "80px",
                      margin: "0px auto",
                    }}
                  ></span>
                </div>
                <div
                  // className="description"
                  className="uvc-sub-heading ult-responsive"
                  style={{
                    fontFamily: "Lora",
                    fontStyle: "italic",
                    fontWeight: "normal",
                    color: "#000000",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <p></p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      // marginLeft: "-9px",
                      // marginRight: "-9px",
                    }}
                  >
                    Lead a Lifestyle that Lets You Balance Work and Play!
                    Dedicated Towards Health and Fitness,
                    <br />
                    North View Symphony Features Above-Class Amenities and
                    Offers Spectacular Views. Come, Discover a Whole New World
                    of Active Livinng.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpb_column vc_column_container vc_col-sm-1">
        <div className="vc_column-inner">
          <div className="wpb_wrapper" />
        </div>
      </div>
      <div
        className="section-content"
        style={
          {
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            // justifyContent: "space-around",
            // alignContent: "center",
            // alignSelf: "center",
            // marginTop: 20,
          }
        }
      >
        <SectionAccordion />
        <SectionAccordion2 />
      </div>
      <div className="image-container">
        <img className="image-size" alt="null" src={amenities} />
      </div>
      {/* <div className="image-container">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="vc_empty_space" style={{ height: "30px" }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="ult-just-icon-wrapper">
                <div className="align-icon" style={{ textAlign: "center" }}>
                  {" "}
                  <div
                    className="image-size"
                    // className="aio-icon-img"
                    // style={{ fontSize: "1500px", display: "inline-block" }}
                  >
                    {" "}
                    <img alt="null" src={amenities} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Amenities;
