import "./App.css";
import Header from "./components/Header";
import Second from "./components/Second";
import Amenities from "./components/Amenities";
import Home from "./components/Home";
import Location from "./components/Location";
import SliderPart from "./components/Slider";
import FloorPlan from "./components/FloorPlan";
import PriceSection from "./components/PriceSection";
import Footer from "./components/Footer";
import Specification from "./components/Specification";

function App() {
  return (
    <div className="App-header">
      <Header />
      <Home />
      <Second />
      <Amenities />
      <SliderPart />
      <FloorPlan />
      <Specification />
      {/* <PriceSection /> */}
      <Location />
      <Footer />
    </div>
  );
}

export default App;
