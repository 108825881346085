import React from "react";
import logo from "../../assets/images/logo.jpeg";
import "./style.css";

const Footer = () => {
  return (
    <div
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      className="footer_container"
      // className="vc_row wpb_row vc_row-fluid ult-vc-hide-row vc_row-has-fill"
      data-rtl="false"
      data-row-effect-mobile-disable="true"
      style={
        {
          // position: "relative",
          // boxSizing: "border-box",
          // width: "100%",
          // paddingLeft: "161px",
          // paddingRight: "161px",
          // background: "rgb(196, 196, 196)",
        }
      }
    >
      <div
        className="upb_row_bg"
        data-bg-override="0"
        style={{
          background: "rgb(196, 196, 196)",
          left: "0px",
        }}
      >
        <div
          className="upb_bg_overlay"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.83)" }}
        ></div>
      </div>
      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="vc_empty_space" style={{ height: "50px" }}></div>
            <div
              id="ultimate-heading-757465002eec3bc16"
              className="uvc-heading ult-adjust-bottom-margin ultimate-heading-757465002eec3bc16 uvc-1778"
              data-hspacer="image_only"
              data-halign="center"
              style={{ textAlign: "center" }}
            >
              <div
                className="uvc-heading-spacer image_only"
                style={{ marginTop: "10px" }}
              >
                <img
                  src={logo}
                  className="ultimate-headings-icon-image"
                  alt="null"
                  style={{ width: "210px" }}
                />
              </div>
              <div
                className="uvc-sub-heading ult-responsive"
                data-ultimate-target=".uvc-heading.ultimate-heading-757465002eec3bc16 .uvc-sub-heading"
                data-responsive-json-new='{"font-size":"desktop:11px;","line-height":"desktop:17px;"}'
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                <p></p>
                <p
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                    textAlign: "center",
                  }}
                >
                  PROJECT RERA NO : PR/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA
                  <br /> /RAA12035/280623
                </p>
                {/* <p style={{ textAlign: "center" }}>
                  AGENT RERA NO : AG/GJ/VADODARA/VADODARA/
                  <br /> AUDA/AA01674/010926R1
                </p> */}
                <p style={{ textAlign: "center" }}>
                  <a
                    style={{
                      color: "#020202",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "bold",
                    }}
                    href="https://bps-projects.com/disclaimer-privacy-policy/"
                  >
                    Disclaimer & Privacy Policy
                  </a>
                </p>
                <p style={{ textAlign: "center" }}>
                  <strong>Disclaimer</strong> : All plans are subject to
                  amendments and approvd by the relevant authorities. All
                  photography and computer imagery are artist's impression and
                  are provided for illustrative and indicative purposes only.
                  While every reasonable care has been taken in providing this
                  information, the developers cannot be held responsible for any
                  inaccuracy Subject to Ahmedabad Jurisdiction.
                </p>
                <p></p>
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: "50px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
