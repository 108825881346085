import React from "react";
import "./style.css";
import Slider from "react-slick";
import Center from "../../assets/images/Center.jpg";
import Garden from "../../assets/images/Garden.jpg";
import GateView from "../../assets/images/Gateview.jpg";
import Background from "../../assets/images/Background.jpg";
import Allview from "../../assets/images/Allview.jpg";

function SliderPart() {
  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const slideStyle = {
    height: "420px",
    width: "500px",
    border: "5px solid #374f5a",
  };

  return (
    <div className="center-image">
      <div
        className="slider-part"
        style={
          {
            // display: "block",
            // width: "100%",
            // maxWidth: "100%",
            // margin: "0 auto",
            // overflow: "hidden",
          }
        }
      >
        <Slider
          {...settings}
          autoplay
          pauseOnHover={false}
          rtl
          arrows={false}
          centerMode={true}
        >
          <div className="slick-slide">
            <img className="slideStyle" src={Center} alt="Image 1" />
          </div>
          <div className="slick-slide">
            <img className="slideStyle" src={Garden} alt="Image 2" />
          </div>
          <div className="slick-slide">
            <img className="slideStyle" src={GateView} alt="Image 3" />
          </div>
          <div className="slick-slide">
            <img className="slideStyle" src={Background} alt="Image 4" />
          </div>
          <div className="slick-slide">
            <img className="slideStyle" src={Allview} alt="Image 5" />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default SliderPart;
