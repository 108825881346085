import React from "react";
import "./style.css";
import * as Icon from "react-bootstrap-icons";
import logo from "../../assets/images/logo.jpeg";
// import since from "../../assets/images/since.png";
import since from "../../assets/images/1996.png";
import northviewlogo from "../../assets/images/North View.png";
import BrochurePDF from "../../BROCHURE.pdf";

function Home() {
  return (
    <div
      id="home"
      data-vc-full-width="true"
      data-vc-full-width-init="true"
      className="home_container"
      // className="vc_row wpb_row vc_row-fluid wpb_animate_when_almost_visible wpb_fadeIn fadeIn vc_column-gap-10 _mPS2id-t wpb_start_animation animated mPS2id-target mPS2id-target-first mPS2id-target-last"
      style={
        {
          // position: "relative",
          // // left: "-156px",
          // boxSizing: "border-box",
          // width: "100%",
          // paddingLeft: "256px",
          // paddingRight: "156px",
          // paddingTop: "100px",
          // top: 100,
        }
      }
    >
      <div style={{ display: "flex", left: 50 }}>
        <img className="blinking-text" alt="null" src={since} />
        {/* <p className="blinking-text">Since 1999</p> */}
      </div>
      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            {/* <div className="ult-just-icon-wrapper vc_custom_1674561710163">
              <div className="align-icon" style={{ textAlign: "center" }}>
                <div
                  className="aio-icon-img"
                  style={{
                    fontSize: "100px",
                    display: "inline-block",
                  }}
                >
                  <img className="img-icon" alt="null" src={northviewlogo} />
                </div>
              </div>
            </div> */}
            <div
              id="ultimate-heading-343464ccd8c1f2000"
              className="uvc-heading ult-adjust-bottom-margin ultimate-heading-343464ccd8c1f2000 uvc-5606"
              data-hspacer="no_spacer"
              data-halign="left"
              style={{ textAlign: "left" }}
            >
              <div
                className="uvc-heading-spacer no_spacer"
                style={{ top: 0 }}
              ></div>
              <div
                className="uvc-sub-heading ult-responsive"
                data-ultimate-target=".uvc-heading.ultimate-heading-343464ccd8c1f2000 .uvc-sub-heading"
                data-responsive-json-new='{"font-size":"desktop:14px;","line-height":"desktop:28px;"}'
                style={{
                  fontFamily: "Cinzel",
                  fontWeight: 700,
                  color: "#374f5a",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <p></p>
                <p
                  style={{
                    marginRight: "0px",
                    marginLeft: "0px",
                    marginBottom: "-18px",
                    textAlign: "center",
                  }}
                >
                  <span style={{ color: "#374f5a" }}>
                    <strong style={{ fontSize: "35px" }}>
                      NORTH VIEW
                      <br />{" "}
                    </strong>
                  </span>
                  <p className="symphony-text">SYMPHONY</p>
                  <span style={{ fontSize: "20px" }}>AHMEDABAD, GUJRAT </span>
                </p>
                <p></p>
              </div>
            </div>
            <div
              id="ultimate-heading-111864ccd8c1f2065"
              className="uvc-heading ult-adjust-bottom-margin ultimate-heading-111864ccd8c1f2065 uvc-1760"
              data-hspacer="line_only"
              data-halign="center"
              style={{ textAlign: "center" }}
            >
              <div
                className="uvc-heading-spacer line_only"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "2px",
                }}
              >
                <span
                  className="uvc-headings-line"
                  style={
                    {
                      // borderStyle: "solid",
                      // borderBottomWidth: "2px",
                      // borderColor: "rgb(55, 79, 90)",
                      // width: "130px",
                      // margin: "0px auto",
                    }
                  }
                ></span>
              </div>
              <div
                className="uvc-sub-heading ult-responsive"
                data-ultimate-target=".uvc-heading.ultimate-heading-111864ccd8c1f2065 .uvc-sub-heading"
                data-responsive-json-new='{"font-size":"desktop:17px;","line-height":"desktop:23px;"}'
                style={{
                  fontFamily: "Lora",
                  fontStyle: "italic",
                  fontWeight: "normal",
                  color: "#000000",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <p></p>
                <p
                  style={{
                    // marginInline: "20px",
                    marginRight: "0px",
                    marginLeft: "0px",
                    marginTop: "15px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  {/* Lead a Lifestyle that Lets You Balance Work and Play!
                  Dedicated Towards Health and Fitness, North View Symphony
                  Features Above-Class Amenities and Offers Spectacular Views.
                  Come, Discover a Whole New World of Active Living. */}
                  Welcome to a delightful residential campus. An ideal 4 BHK
                  Residential flats with the indulgence of spacious living
                  spaces, a touch of exclusivity with ample open spaces in your
                  flats as well as the entire campus, a well connected fast
                  developing location.
                </p>

                <p style={{ fontSize: "18px" }}>
                  A unique and peaceful sanctuary, would be the place where you
                  would love to live with your entire family. A place where you
                  could see your children growing up in the safe and secure
                  environs, a place where you could see your parents enjoying
                  their greying years with the company of others of their age, a
                  place where you would love to come back after
                  a hard days work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner_content">
        <div className="wpb_animate_when_almost_visible wpb_fadeInLeft fadeInLeft wpb_column vc_column_container vc_col-sm-6 wpb_start_animation animated">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="vc_empty_space" style={{ height: "10px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <div
                className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1673186668667"
                style={{ width: 500 }}
              >
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                  <div className="vc_column-inner vc_custom_1673093949495">
                    <div className="wpb_wrapper">
                      <div
                        className="vc_empty_space"
                        style={{ height: "20px" }}
                      >
                        <span className="vc_empty_space_inner"></span>
                      </div>
                      <div className="wpb_text_column wpb_content_element">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "center" }}>
                            <span
                              className="animated bounceIn infinite"
                              style={{
                                animationDuration: "3s",
                                height: "45px",
                                fontFamily: "cinzel",
                                marginBottom: "25px",
                                lineHeight: "24px",
                                fontSize: "20px",
                                animationFillMode: "both",
                                animationIterationCount: "infinite",
                                animationName: "bounceIn",
                                boxSizing: "border-box",
                                display: "block",
                                outline: "0px",
                                color: "#374f5a",
                                textTransform: "uppercase",
                                animation: "bounceIn 3s infinite",
                              }}
                            >
                              <strong style={{ fontSize: "22px" }}>
                                Exclusive price benefit
                                <br /> for units
                                <br />{" "}
                              </strong>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="ult-just-icon-wrapper">
                        <div
                          className="align-icon"
                          style={{ textAlign: "center" }}
                        >
                          <div
                            className="aio-icon-img"
                            style={{
                              fontSize: "120px",
                              display: "inline-block",
                            }}
                          >
                            <img className="img-icon" alt="null" src={logo} />
                          </div>
                        </div>
                      </div>
                      <div
                        className="vc_empty_space"
                        style={{ height: "20px" }}
                      >
                        <span className="vc_empty_space_inner"></span>
                      </div>
                      <div className="wpb_text_column wpb_content_element">
                        <div className="wpb_wrapper">
                          <p style={{ textAlign: "center" }}>
                            <span
                              className="animated bounceIn infinite"
                              style={{
                                animationDuration: "3s",
                                height: "45px",
                                fontFamily: "cinzel",
                                marginBottom: "25px",
                                lineHeight: "26px",
                                fontSize: "17px",
                                animationFillMode: "both",
                                animationIterationCount: "infinite",
                                animationName: "bounceIn",
                                boxSizing: "border-box",
                                display: "block",
                                outline: "0px",
                                color: "#374f5a",
                                textTransform: "uppercase",
                                animation: "bounceIn 3s infinite",
                              }}
                            >
                              <strong style={{ fontSize: "18px" }}>
                                hanging balcony with
                                <br /> 180 degree panoramic view
                                <br />{" "}
                              </strong>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* <div className="vc_custom_1674736373171 ubtn-ctn-center">
                        <a
                          className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-custom"
                          data-toggle="fc_modal"
                          data-target="#modal-64ccd8c215f7e"
                        >
                          <button
                            type="button"
                            id="ubtn-5701"
                            className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-custom ubtn-no-hover-bg ulta-shrink ubtn-sep-icon ubtn-sep-icon-at-left ubtn-center tooltip-64ccd8c1f2e54"
                            data-hover=""
                            data-border-color="#374f5a"
                            data-bg=""
                            data-hover-bg=""
                            data-border-hover=""
                            data-shadow-hover=""
                            data-shadow-click="none"
                            data-shadow=""
                            data-shd-shadow=""
                            data-ultimate-target="#ubtn-5701"
                            data-responsive-json-new='{"font-size":"desktop:15px;"}'
                            style={{
                              fontFamily: "Lora",
                              fontWeight: "normal",
                              fontStyle: "normal",
                              width: "180px",
                              minHeight: "40px",
                              padding: "0px",
                              borderRadius: "3px",
                              borderWidth: "2px",
                              borderColor: "#374f5a",
                              borderStyle: "solid",
                              color: "#374f5a",
                            }}
                            fdprocessedid="yrd0dk"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <Icon.GiftFill
                                  size={16}
                                  style={{ marginBottom: 3 }}
                                />
                              </span>
                              <span
                                className="ubtn-hover"
                                style={{ backgroundColor: "" }}
                              ></span>
                              <span
                                className="ubtn-data ubtn-text"
                                style={{ marginLeft: 10, fontSize: 14 }}
                              >
                                Register Now
                              </span>
                            </div>
                          </button>
                        </a>
                      </div> */}
                      <div
                        className="vc_empty_space"
                        style={{ height: "25px" }}
                      >
                        <span className="vc_empty_space_inner"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text_container">
          <div
            className="wpb_animate_when_almost_visible wpb_fadeInRight fadeInRight wpb_column vc_column_container vc_col-sm-6 wpb_start_animation animated"
            // style={{ width: "50%" }}
          >
            <div className="vc_column-inner vc_custom_1674561452343">
              <div className="wpb_wrapper">
                <div
                  id="ultimate-heading-303364ccd8c1f3118"
                  className="uvc-heading ult-adjust-bottom-margin ultimate-heading-303364ccd8c1f3118 uvc-6689"
                  data-hspacer="no_spacer"
                  data-halign="center"
                  style={{ textAlign: "center" }}
                >
                  <div
                    className="uvc-heading-spacer no_spacer"
                    style={{ top: 0 }}
                  ></div>
                  <div
                    className="uvc-sub-heading ult-responsive"
                    data-ultimate-target=".uvc-heading.ultimate-heading-303364ccd8c1f3118 .uvc-sub-heading"
                    data-responsive-json-new='{"font-size":"desktop:18px;","line-height":"desktop:33px;"}'
                    style={{
                      fontFamily: "Lora",
                      fontStyle: "italic",
                      fontWeight: "normal",
                      color: "#000000",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    <p></p>
                    <ol className="text-style">
                      <li>
                        Fine Balance Between
                        <br /> Recreational Spaces and Unique Residences
                      </li>
                      <li>Good Basement and Parking Space</li>
                      <li>Spectacular Views and Above-Class Amenities</li>
                      <li>Co-Working Setup for Efficient Work from Home</li>
                      <li>Tropical Walk for Greener and Healthier Living.</li>
                    </ol>
                    <p></p>
                  </div>
                </div>
                <div className="button-container">
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <a
                      className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-custom"
                      data-toggle="fc_modal"
                      data-target="#modal-64ccd8c21469b"
                      href={BrochurePDF}
                    >
                      <button
                        type="button"
                        style={{
                          fontFamily: "Lora",
                          fontWeight: "normal",
                          fontStyle: "normal",
                          width: "180px",
                          minHeight: "40px",
                          padding: "0px",
                          borderRadius: "3px",
                          borderWidth: "2px",
                          borderColor: "#b20810",
                          borderStyle: "solid",
                          background: "#b20810",
                          color: "#ffffff",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon.FileEarmarkPdfFill
                              size={16}
                              style={{ marginBottom: 1 }}
                            />
                          </span>

                          <span
                            className="ubtn-data ubtn-text"
                            style={{ marginLeft: 15, fontSize: 14 }}
                          >
                            Brochure
                          </span>
                        </div>
                      </button>
                    </a>
                  </div>
                  <div className="vc_empty_space" style={{ height: "15px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                  {/* <div className="wpb_column vc_column_container vc_col-sm-6">
                    <a
                      className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-custom"
                      href="tel:+91-123-456-7890"
                    >
                      <button
                        type="button"
                        style={{
                          fontFamily: "Lora",
                          fontWeight: "normal",
                          fontStyle: "normal",
                          width: "195px",
                          minHeight: "40px",
                          padding: "0px",
                          borderRadius: "3px",
                          borderWidth: "3px",
                          borderColor: "#374f5a",
                          borderStyle: "solid",
                          background: "#374f5a",
                          color: "#ffffff",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon.TelephoneFill
                              size={16}
                              style={{ marginBottom: 1 }}
                            />
                          </span>

                          <span
                            className="ubtn-data ubtn-text"
                            style={{ marginLeft: 15, fontSize: 14 }}
                          >
                            CALL +91-123-456-7890
                          </span>
                        </div>
                      </button>
                    </a>
                  </div> */}
                  <div className="vc_empty_space" style={{ height: "15px" }}>
                    <span className="vc_empty_space_inner"></span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="last_button">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <a href="tel:+91-123-456-7890">
                  <button
                    type="button"
                    className="btn-custom"
                    style={{
                      fontFamily: "Lora",
                      fontWeight: "normal",
                      fontStyle: "normal",
                      // width: "400px",
                      minHeight: "70px",
                      padding: "0px",
                      borderRadius: "3px",
                      borderWidth: "2px",
                      borderColor: "#374f5a",
                      borderStyle: "solid",
                      color: "#374f5a",
                      flexDirection: "row",
                      marginLeft: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon.TelephoneFill
                          size={20}
                          // style={{ marginBottom: 5 }}
                        />
                      </span>
                      <span
                        className="ubtn-data ubtn-text"
                        style={{ marginLeft: 5, fontSize: 22 }}
                      >
                        CALL +91-123-456-7890
                      </span>
                    </div>
                  </button>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
