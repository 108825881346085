import React from "react";
import MasterLayout from "../MasterLayout";
import UnitPlansLayout from "../UnitPlansLayout";
import "./style.css";
function FloorPlan() {
  return (
    <div
      id="floorplan"
      className="floorplan"
      style={
        {
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "center",
          // paddingTop: "20px",
        }
      }
    >
      <MasterLayout />
      <UnitPlansLayout />
    </div>
  );
}

export default FloorPlan;
