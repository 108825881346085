import React from "react";
import "./style.css";
import * as Icon from "react-bootstrap-icons";
import backgroundImage from "../../assets/images/Background.jpg";
import gateview from "../../assets/images/Gateview.jpg";
import Dream from "../../assets/images/Dream.jpeg";

function Second() {
  return (
    <div
      className="second"
      // className="vc_row wpb_row vc_row-fluid vc_column-gap-10 ult-vc-hide-row vc_row-has-fill"
      style={
        {
          // display: "flex",
          // position: "relative",
          // // left: "20px",
          // boxSizing: "border-box",
          // width: "100%",
          // height: "750px",
          // paddingLeft: "256px",
          // paddingRight: "156px",
          // marginTop: 50,
          // marginBottom: 50,
        }
      }
    >
      <div
        className="upb_row_bg vcpb-default"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
          backgroundColor: "rgba(0, 0, 0, 0)",
          backgroundImage: `url(${backgroundImage})`,
          backgroundAttachment: "fixed",
          minWidth: "100%",
          width: "100%",
        }}
      >
        <div
          className="upb_bg_overlay"
          style={{ backgroundColor: "rgba(0,0,0,0.84)" }}
        ></div>
      </div>
      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="vc_empty_space" style={{ height: "30px" }}>
              <span className="vc_empty_space_inner"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="image_row">
        <div className="wpb_column vc_column_container vc_col-sm-6">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="vc_empty_space" style={{ height: "55px" }}>
                <span className="vc_empty_space_inner"></span>
              </div>
              <div className="ult-just-icon-wrapper vc_custom_1674743395703">
                <div className="align-icon" style={{ textAlign: "center" }}>
                  <a
                    className="aio-tooltip 64ccd8c1f3e39"
                    data-toggle="fc_modal"
                    data-placement=""
                    data-target="#modal-64ccd8c21c4e4"
                  >
                    <div
                      className="first-image"
                      // style={{
                      //   fontSize: "450px",
                      //   display: "inline-block",
                      // }}
                    >
                      <img alt="null" src={Dream} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-6">
          {/* <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="ult-just-icon-wrapper vc_custom_1674743352367"> */}
          {/* <div className="align-icon" style={{ textAlign: "center" }}> */}
          <a>
            <div
              // className="aio-icon-img"
              className="second-image"
              // style={{
              //   fontSize: "500px",
              //   borderStyle: "solid",
              //   borderColor: "#ffffff",
              //   borderWidth: "2px",
              //   padding: "4px",
              //   borderRadius: "0px",
              //   display: "inline-block",
              //   marginTop: 150,
              // }}
            >
              {/* <div className="second-image"> */}
              <img alt="null" src={gateview} />
              {/* </div> */}
            </div>
          </a>
          {/* </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Second;
