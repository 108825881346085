import React, { useState } from "react";
import "./style.css";

function SectionAccordion2() {
  const [activePanel, setActivePanel] = useState(0);

  const togglePanel = (index) => {
    if (activePanel === index) {
      return;
    } else {
      setActivePanel(index);
    }
  };

  const sections = [
    {
      title: "Open Gazebo",
      description:
        "Gazebo in flats is a communal outdoor space or structure within a residential complex, typically featuring a covered pavilion or open-sided shelter where residents can relax, and enjoy outdoor activities.",
      imageSrc: require("../../assets/images/gazebo.jpg"),
    },
    {
      title: "LandScape Garden",
      description:
        "It offers residents a serene and natural environment to relax, socialize, and connect with nature, enhancing the overall living experience in a high-rise setting.",
      imageSrc: require("../../assets/images/landscape-garden.jpeg"),
    },
    {
      title: "Children Play Area",
      description:
        "It typically includes various age-appropriate playground equipment and often features vibrant and child-friendly design elements.",
      imageSrc: require("../../assets/images/playarea.jpg"),
    },
  ];

  return (
    <div className="centered-container">
      <div className="section-accordion">
        {sections.map((section, index) => (
          <div
            className="section-panel"
            // className={`section-panel ${activePanel === index ? "active" : ""}`}
            key={index}
            onClick={() => togglePanel(index)}
            style={{
              border: "1px solid #374f5a",
              marginBottom: "10px",
              padding: "10px",
              // width: "500px",
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: "#374f5a",
              // marginLeft: "10px",
            }}
          >
            <div className="section-heading">
              <h4
                className="section-title"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "cinzel",
                  fontWeight: "bold",
                  color: "#fff",
                  marginTop: 10,
                  textTransform: "uppercase",
                }}
              >
                {section.title}
                <i
                  className={`section-icon ${
                    activePanel === index ? "active" : ""
                  }`}
                  style={{
                    marginLeft: "auto",
                    transform: activePanel === index ? "rotate(45deg)" : "none",
                  }}
                >
                  &#43;
                </i>
              </h4>
            </div>
            {activePanel === index && (
              <div
                className="section-body"
                style={{
                  border: "1px solid #374f5a",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <div
                  className="section-description"
                  style={{
                    marginBottom: "10px",
                    fontFamily: "cinzel",
                  }}
                >
                  {section.description}
                </div>
                <div
                  className="section-image"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    className="img-icon"
                    alt="null"
                    src={section.imageSrc}
                    style={{
                      maxWidth: "100%",
                      height: 300,
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionAccordion2;
