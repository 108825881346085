import React from "react";
import Pricing from "../Pricing";
import PaymentSchedule from "../PaymentSchedule";
import "./style.css";

function PriceSection() {
  return (
    <div id="price" className="price-section" style={{}}>
      <Pricing />
      <PaymentSchedule />
    </div>
  );
}

export default PriceSection;
