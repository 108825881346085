import React, { useState } from "react";

const UnitPlansLayout = () => {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowImagePopup(true);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
    setShowImagePopup(false);
  };

  const [activePanel, setActivePanel] = useState(0);

  const togglePanel = (index) => {
    if (activePanel === index) {
      return;
    } else {
      setActivePanel(index);
    }
  };

  const sections = [
    {
      title: "Block - A (Typical Floor Plan)",
      description: "UNIT PLAN",
      imageSrc: require("../../assets/images/Block-A(floor).png"),
    },
    {
      title: "Block - A (First & Top Floor)",
      description: "UNIT PLAN",
      imageSrc: require("../../assets/images/Block-A(first).png"),
    },
    {
      title: "Block - B (Typical Floor Plan)",
      description: "UNIT PLAN",
      imageSrc: require("../../assets/images/Block-B(floor).png"),
    },
  ];

  return (
    <div
      className="centered-container"
      style={{
        backgroundColor: "#374f5a",
        width: "100%",
        // display: "flex",
        // justifyContent: "center",
        // flexDirection: "column",
      }}
    >
      <div
        id="ultimate-heading-839865002eec35310"
        className="uvc-heading ult-adjust-bottom-margin ultimate-heading-839865002eec35310 uvc-9739 txtx"
        data-hspacer="line_only"
        data-halign="center"
        style={{ textAlign: "center" }}
      >
        <div
          className="uvc-main-heading ult-responsive"
          data-ultimate-target=".uvc-heading.ultimate-heading-839865002eec35310 h2"
          data-responsive-json-new='{"font-size":"desktop:30px;","line-height":"desktop:28px;"}'
        >
          <h2
            style={{
              fontFamily: "Cinzel",
              fontWeight: 700,
              color: "#fff",
              marginBottom: "0px",
              marginTop: 80,
            }}
          >
            UNIT PLANS LAYOUT
          </h2>
        </div>
        <div
          className="uvc-heading-spacer line_only"
          style={{ marginTop: "20px", marginBottom: "10px", height: "2px" }}
        >
          <span
            className="uvc-headings-line"
            style={{
              display: "flex",
              borderStyle: "solid",
              borderBottomWidth: "2px",
              borderColor: "#fff",
              width: "120px",
              margin: "0px auto",
            }}
          ></span>
        </div>
      </div>
      <div
        className="section-accordion"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 40,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {sections.map((section, index) => (
          <div
            className={`section-panel ${activePanel === index ? "active" : ""}`}
            key={index}
            onClick={() => togglePanel(index)}
            style={{
              border: "1px solid #374f5a",
              marginBottom: "10px",
              padding: "10px",
              // width: "500px",
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: "#374f5a",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <div className="section-heading">
              <h4
                className="section-title"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "cinzel",
                  fontWeight: "bold",
                  color: "#fff",
                  marginTop: 10,
                  textTransform: "uppercase",
                }}
              >
                {section.title}
                <i
                  className={`section-icon ${
                    activePanel === index ? "active" : ""
                  }`}
                  style={{
                    marginLeft: "auto",
                    transform: activePanel === index ? "rotate(45deg)" : "none",
                  }}
                >
                  &#43;
                </i>
              </h4>
              <div
                className="uvc-heading-spacer line_only"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  height: "2px",
                }}
              >
                <span
                  className="uvc-headings-line"
                  style={{
                    display: "flex",
                    borderStyle: "solid",
                    borderBottomWidth: "2px",
                    borderColor: "#fff",
                    margin: "0px auto",
                  }}
                ></span>
              </div>
            </div>
            {activePanel === index && (
              <div
                className="section-body"
                style={{
                  border: "1px solid #374f5a",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <div
                  className="section-description"
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    fontFamily: "Cinzel",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  {section.description}
                </div>
                <div
                  className="section-image"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={() => handleImageClick(section.imageSrc)}
                >
                  <img
                    className="img-icon"
                    alt="null"
                    src={section.imageSrc}
                    style={{
                      maxWidth: "100%",
                      height: 300,
                      borderRadius: "5px",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {showImagePopup && (
        <div className={`image-popup ${showImagePopup ? "show" : ""}`}>
          <div className="image-popup-content">
            <img
              className="img-full-screen"
              src={selectedImage}
              alt="Full-Screen"
            />
            <button className="close-button" onClick={closeImagePopup}>
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitPlansLayout;
