import React, { useState } from "react";
import Typical_Floor_Plan from "../../assets/images/Typical_Floor_Plan.png";
import "./style.css";

const MasterLayout = () => {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowImagePopup(true);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
    setShowImagePopup(false);
  };

  return (
    <div
      className="centered-container"
      style={{ width: "100%", marginTop: 80 }}
    >
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <div
            id="ultimate-heading-839865002eec35310"
            className="uvc-heading ult-adjust-bottom-margin ultimate-heading-839865002eec35310 uvc-9739 txtx"
            data-hspacer="line_only"
            data-halign="center"
            style={{ textAlign: "center" }}
          >
            <div
              className="uvc-main-heading ult-responsive"
              data-ultimate-target=".uvc-heading.ultimate-heading-839865002eec35310 h2"
              data-responsive-json-new='{"font-size":"desktop:30px;","line-height":"desktop:28px;"}'
            >
              <h2
                style={{
                  fontFamily: "Cinzel",
                  fontWeight: 700,
                  color: "#374f5a",
                  marginBottom: "0px",
                }}
              >
                MASTER LAYOUT PLAN
              </h2>
            </div>
            <div
              className="uvc-heading-spacer line_only"
              style={{ marginTop: "20px", marginBottom: "10px", height: "2px" }}
            >
              <span
                className="uvc-headings-line"
                style={{
                  display: "flex",
                  borderStyle: "solid",
                  borderBottomWidth: "2px",
                  borderColor: "rgb(55, 79, 90)",
                  width: "120px",
                  margin: "0px auto",
                }}
              ></span>
            </div>
          </div>
          <div className="vc_empty_space" style={{ height: "55px" }}>
            <span className="vc_empty_space_inner"></span>
          </div>
          <div className="vc_empty_space" style={{ height: "10px" }}>
            <span className="vc_empty_space_inner"></span>
          </div>
          <div className="ult-just-icon-wrapper vc_custom_1692971367518">
            <div className="align-icon" style={{ textAlign: "center" }}>
              <a
                className="aio-tooltip 65002eec3547b"
                data-toggle="fc_modal"
                data-placement=""
                data-target="#modal-65002eec3e7ff"
              >
                <div className="image_icon">
                  <div
                    className="aio-icon-img"
                    style={{
                      fontSize: "600px",
                      borderStyle: "solid",
                      borderColor: "#374f5a",
                      borderWidth: "2px",
                      // padding: "3px",
                      borderRadius: "0px",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onClick={() => handleImageClick(Typical_Floor_Plan)}
                  >
                    <img
                      className="img-icon"
                      alt="null"
                      src={Typical_Floor_Plan}
                      // onClick={handleImageClick}
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="vc_empty_space" style={{ height: "15px" }}>
            <span className="vc_empty_space_inner"></span>
          </div>
        </div>
      </div>
      {showImagePopup && (
        <div className={`image-popup ${showImagePopup ? "show" : ""}`}>
          <div className="image-popup-content">
            <img
              className="img-full-screen"
              src={selectedImage}
              alt="Full-Screen"
            />
            <button className="close-button" onClick={closeImagePopup}>
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterLayout;
