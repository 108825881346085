import React from "react";
import "./style.css";
import * as Icon from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlorinSign,
  faBath,
  faKitchenSet,
  faWindowMaximize,
  faHouseChimneyWindow,
  faCubesStacked,
} from "@fortawesome/free-solid-svg-icons";

function Specification() {
  return (
    <div className="specification">
      <h2>Specification</h2>
      <div className="spec-row">
        <div className="spec-details">
          {/* <FontAwesomeIcon icon={faPaintBrush} className="icon" /> */}
          <Icon.PaintBucket color="#374f5a" size={30} />
          <strong>Wall Finish:</strong>
          <ul>
            <li>- Interior Plaster With Double Putty Coat</li>
            <li>
              - Exterior - Double Coat Plaster with Weatherproof Quality Paint
            </li>
          </ul>
        </div>
        <div className="spec-details">
          <Icon.DoorOpen color="#374f5a" size={30} />
          <strong>Doors:</strong>
          <ul>
            <li>- Flush doors without Laminates.</li>
            <li>- Stundard safely Lock system in main door.</li>
          </ul>
        </div>
      </div>
      <div className="spec-row">
        <div className="spec-details">
          <Icon.Buildings color="#374f5a" size={30} />
          <strong>Structure:</strong>
          <ul>
            <li>- Earthquake Resistance</li>
            <li>- RCC Frame Structure</li>
          </ul>
        </div>
        <div className="spec-details">
          <Icon.ShopWindow color="#374f5a" size={30} />
          <strong>Windows:</strong>
          <ul>
            <li>- Anodized Aluminum Domel Section</li>
            <li>
              - Sliding Windows with Finished with Natural Stone eighter Frame.
            </li>
          </ul>
        </div>
      </div>
      <div className="spec-row">
        <div className="spec-details">
          <FontAwesomeIcon icon={faCubesStacked} className="icon" />
          {/* <Icon.Tools color="#374f5a" size={30} /> */}
          <strong>Flooring:</strong>
          <ul>
            <li>- Premium quality vitrified Tiles </li>
          </ul>
        </div>
        <div className="spec-details">
          <FontAwesomeIcon
            icon={faKitchenSet}
            className="icon"
            size="2x"
            color="#374f5a"
          />
          <strong>Kitchen:</strong>
          <ul>
            <li>
              -Sandwich Granite Platform or Composite Platform or equivalent
            </li>
            <li>- SS sink Designer Wall tiles upto Lintel Level</li>
          </ul>
        </div>
      </div>
      <div className="spec-row">
        <div className="spec-details">
          <Icon.Plug color="#374f5a" size={30} />
          <strong>Electrical:</strong>
          <ul>
            <li>
              - Concealed copper wiring as per ISI standard. (RR Cabel or
              Finolex or equivalent)
            </li>
            <li>- Switches of Reputed Brand as per ISI standard</li>
            <li>- Separate electrification in all Room.</li>
            <li>- Ac Points in living Area & all Bedrooms</li>
            <li>- TV Points in Living Room and Master bed Rooms</li>
          </ul>
        </div>
        <div className="spec-details">
          <FontAwesomeIcon
            icon={faBath}
            className="icon"
            size="2x"
            color="#374f5a"
          />
          {/* <Icon.PaintBucket color="#374f5a" size={30} /> */}
          <strong>Bathrooms:</strong>
          <ul>
            <li>- Standard UPVC & CPVC pipe fitting.</li>
            <li>- Bath Accessories Hindware of equivalent</li>
            <li>- Standard quality sanitory ware.</li>
            <li>- Geyser Points in all Bathroom</li>
            <li>- Designer wall Tiles up to Lintel Level.</li>
          </ul>
        </div>
      </div>
      <div className="spec-row">
        <div className="spec-details">
          <Icon.DropletHalf color="#374f5a" size={30} />
          <strong>Others:</strong>
          <ul>
            <li>- 24 x 7 Hour Power Backup for Common Area</li>
            <li>
              - Overhead & Underground Water Tank with Auto Control System
            </li>
            <li>
              - 2 Premium Quality Elevator with Power Back up in Each Tower
            </li>
            <li>
              - Fire Safety System as per rules, 24 x 7 CCTV Surveillance Brick
              Bat Water Proofing along with china mosaic flooring on terrace
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Specification;
