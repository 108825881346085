import React, { useState } from "react";
import "./style.css";

function SectionAccordion() {
  const [activePanel, setActivePanel] = useState(0);

  const togglePanel = (index) => {
    if (activePanel === index) {
      setActivePanel(index);
    } else {
      setActivePanel(index);
    }
  };

  const sections = [
    {
      title: "Multipurpose Hall",
      description:
        "The multipurpose hall can serve as a common area for residents to gather for community meetings, events, and social gatherings. This helps foster a sense of community among the residents.",
      imageSrc: require("../../assets/images/Hall.jpeg"),
    },
    {
      title: "Fitness Studio",
      description:
        "A fitness studio in flats is a convenient and compact workout space located within a residential building, offering residents the opportunity to stay active and maintain a healthy lifestyle without leaving the comfort of their home.",
      imageSrc: require("../../assets/images/fitness.jpg"),
    },
    {
      title: "Jogging Track",
      description:
        "It provides residents with a convenient and safe space to engage in jogging or brisk walking, promoting fitness and well-being within the confines of their living environment.",
      imageSrc: require("../../assets/images/jogging.jpg"),
    },
  ];

  return (
    <div className="centered-container">
      <div className="section-accordion">
        {sections.map((section, index) => (
          <div
            // className={`section-panel ${activePanel === index ? "active" : ""}`}
            className="section-panel"
            key={index}
            onClick={() => togglePanel(index)}
            style={{
              border: "1px solid #374f5a",
              marginBottom: "10px",
              padding: "10px",
              // width: "500px",
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: "#374f5a",
            }}
          >
            <div className="section-heading">
              <h4
                className="section-title"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "cinzel",
                  fontWeight: "bold",
                  color: "#fff",
                  marginTop: 10,
                  textTransform: "uppercase",
                }}
              >
                {section.title}
                <i
                  className={`section-icon ${
                    activePanel === index ? "active" : ""
                  }`}
                  style={{
                    marginLeft: "auto",
                    transform: activePanel === index ? "rotate(45deg)" : "none",
                  }}
                >
                  &#43;
                </i>
              </h4>
            </div>
            {activePanel === index && (
              <div
                className="section-body"
                style={{
                  border: "1px solid #374f5a",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <div
                  className="section-description"
                  style={{
                    marginBottom: "10px",
                    fontFamily: "cinzel",
                    // color: "white",
                  }}
                >
                  {section.description}
                </div>
                <div
                  className="section-image"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    className="img-icon"
                    alt="null"
                    src={section.imageSrc}
                    style={{
                      maxWidth: "100%",
                      height: 300,
                      borderRadius: "5px",
                      //   border: "5px solid gray",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionAccordion;
